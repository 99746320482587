

















// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Links extends Vue {

  getVersion() {
    return process.env.VUE_APP_COPYRIGHT + ' ' + process.env.VUE_APP_VERSION_NUMBER;
  }

}
