import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { AuthData, Getters, Mutations, Actions } from '@/models/Auth.model';

// Inital state
const state: AuthData = {
  token: '',
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, AuthData> = {
  token: (state) => {    // do expiry check on current token
    return localStorage.getItem('token') || state.token;
  },
};

// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, AuthData> = {
  token(state, { token }) {
    state.token = token;
    localStorage.setItem('token', token);
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, AuthData, Mutations, Getters> = {
  token({ commit }: any, payload: any) {
    commit('token', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<AuthData, Getters, Mutations, Actions>('AuthModule');

export const AuthModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
