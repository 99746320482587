






import { Component, Prop, Vue } from 'vue-property-decorator';
import '@/styles/overrides.sass';

@Component
export default class App extends Vue {
}
